'use strict';

angular.module('cpformplastApp.jobs')
    .factory('Machine', function(SubjobSchedule, MachineSchedule) {

    class Machine {
        constructor({id, number, type, subjobSchedules, schedule, activeSubjobSchedule}) {
          this.id = id;
          this.number = Number.parseInt(number, 10);
          this.type = type;
          this.subjobSchedules = subjobSchedules ? subjobSchedules.map(o => new SubjobSchedule(o)) : [];
          this.schedule = schedule ? schedule.map(o => new MachineSchedule(o)) : [];
          this.activeSubjobSchedule = activeSubjobSchedule;
        }

        getScheduledTime() {
          var hours = 0;
          for(var i=0 ; i<this.subjobSchedules.length ; i++) {
            hours += this.subjobSchedules[i].hoursAmount;
          }
          return hours;
        }

        isDefaultSchedule() {
          var isDefaultSchedule = true;
          for (var i=0 ; i<this.schedule.length ; i++) {
            if (!this.schedule[i].isDefaultSchedule) {
              isDefaultSchedule = false;
            }
          }
          return isDefaultSchedule;
        }
    }

    return Machine;
});
